
.App-background {
  background-color: #282c34;
/*
  background-color: #13a4F4;
*/
}

a {
  color: #0077FF;
}


.App {
  text-align: left;
  margin: 0px 60px 0px;
  padding-bottom: 40px
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.covid19 {
  color: red;
}


.App-title-text {
  font-size: 64px;
}

.App-tagline-text {
  font-size: 36px;
}


.App-goal-text {
  font-size: 30px;
}

.App-text {
  font-size: 30px;
}


.App-list {
  font-size: 24px;
  list-style-type: none;
/*
  padding-bottom: 26px;
  padding-top: 10px
  line-height:150%  
*/
}

.App-list li {
  padding: .5em;
}


.App-list-heading {
  color: #FFCC00;
}


.App-list-contact-item {
  font-size: .8em;
  padding: 1em;
}



.App-link {
  color: #61dafb;
}


.contact-email {
  color: red;
  font-size: .8em;
  font-family: 'Courier', monospace;
}



.contact-item {
  font-size: .8em;
  padding: 1em;
  text-indent: False;
  padding-left: 2em;
  text-indent: -1em;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
